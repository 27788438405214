<template>
  <v-container id="user-profile" fluid tag="section">
    <v-row justify="center" class="row-alignment">
      <v-row justify="center" v-if="!editone && !edittwo">
        <v-col class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
          <v-card
            class="images-container-card card-height"
            v-if="user.userType === 'supplier' && UserProfileCard"
          >
            <v-row>
              <v-col cols="6" md="6">
                <v-card-text class="text-left">
                  <h2 class="title_style">User Profile</h2>
                </v-card-text>
              </v-col>
              <v-col cols="6" md="6" class="btn-container">
                <md-button @click="editoneClicked" class="btn_style"
                  >Edit</md-button
                >
              </v-col>
              <v-col cols="12" md="12">
                <v-avatar
                  size="150"
                  v-if="!preview"
                  :class="avatar ? 'image_style' : 'preview_image_style'"
                >
                  <v-img :src="imageUrl" />
                </v-avatar>
                <v-avatar size="150" v-else>
                  <v-img :src="preview" />
                </v-avatar>
              </v-col>
              <v-col cols="12" md="12" class="text-center pt-0">
                <p class="name_style " data-cy="full-name">
                  {{ Show.firstName }} {{ Show.lastName }}
                </p>
                <p class="subtitle_data">
                  Account Type: {{ getUserType(user.userType) }}
                </p>
                <p class="subtitle_data">
                  {{ form.dateOfBirth }}
                </p>
                <v-divider justify="center" class="divider_style" />
              </v-col>
              <v-col class="text-left ml-7 col-10">
                <p class="subtitle_style mb-6">
                  Contact Information
                </p>
                <div class="mb-5">
                  <span class="subtitle_data">
                    Email Address:
                  </span>
                  <span class="subtitle_data" v-if="user.email">
                    {{ user.email }}
                  </span>
                </div>

                <div class="mb-5">
                  <span class="subtitle_data" v-if="Show.phoneNumber">
                    Phone Number:
                  </span>

                  <span
                    class="subtitle_data font-weight-bold ml-1 mb-4"
                    v-if="Show.phoneNumber"
                  >
                    {{ Show.phoneNumber }}
                  </span>
                </div>
                <p class="subtitle_data" v-if="Show.billingAddressLine1">
                  Billing Address:
                </p>

                <p class="subtitle_data" v-if="Show.billingAddressLine1">
                  {{ Show.billingAddressLine1 }} <br />
                  {{ Show.billingAddressLine2 }}
                  <br v-if="Show.billingAddressLine2" />
                  {{ Show.billingAddressCity }} <br />
                  {{ Show.billingAddressState }}
                  <br v-if="Show.billingAddressState" />
                  {{ Show.billingAddressPostal }} <br />
                  {{ Show.billingAddressCountry }}
                </p>

                <p class="subtitle_data" v-if="Show.deliveryAddressLine1">
                  Delivery Address:
                </p>
                <p class="subtitle_data" v-if="Show.deliveryAddressLine1">
                  {{ Show.deliveryAddressLine1 }} <br />
                  {{ Show.deliveryAddressLine2 }}
                  <br v-if="Show.deliveryAddressLine2" />
                  {{ Show.deliveryAddressCity }} <br />
                  {{ Show.deliveryAddressState }}
                  <br v-if="Show.deliveryAddressLineState" />
                  {{ Show.deliveryAddressPostal }} <br />
                  {{ Show.deliveryAddressCountry }}
                </p>
              </v-col>
            </v-row>
          </v-card>
          <v-card
            v-if="user.userType !== 'supplier' && UserProfileCard"
            class="profile-container-card"
          >
            <v-row>
              <v-col cols="12" md="12">
                <v-card-text class="text-left">
                  <h2 class="title_style">Personal Information</h2>
                </v-card-text>
              </v-col>
              <v-col cols="12" md="12">
                <v-avatar
                  size="150"
                  v-if="!preview"
                  :class="avatar ? 'image_style' : 'preview_image_style'"
                >
                  <v-img :src="imageUrl" />
                </v-avatar>
                <v-avatar size="150" v-else>
                  <v-img :src="preview" />
                </v-avatar>
              </v-col>
              <v-col cols="12" md="12" class="text-center pt-0">
                <p class="name_style " data-cy="full-name">
                  {{ Show.firstName }} {{ Show.lastName }}
                </p>
                <p class="subtitle_data">
                  Account Type: {{ getUserType(user.userType) }}
                </p>
                <p class="subtitle_data">
                  {{ form.dateOfBirth }}
                </p>
              </v-col>
              <v-col cols="12" md="12" class="edit-btn-container">
                <md-button @click="editoneClicked" class="edit_btn_style"
                  >Edit</md-button
                >
              </v-col>
              <v-col cols="12">
                <v-divider justify="center" class="divider_style" />
              </v-col>
              <v-col md="11" class="text-left ml-7 mr-7">
                <p class="subtitle_style mb-6">
                  Contact Information
                </p>

                <div class="mb-5">
                  <span
                    class="subtitle_data"
                    v-if="user.userType === 'customer'"
                  >
                    Business Name:
                  </span>
                  <span
                    class="subtitle_data"
                    v-if="user.userType === 'customer'"
                  >
                    {{ form.brandName }}
                  </span>
                </div>

                <div class="mb-5">
                  <span class="subtitle_data">
                    Email Address:
                  </span>
                  <span class="subtitle_data ml-1" v-if="user.email">
                    {{ user.email }}
                  </span>
                </div>

                <div class="mb-5">
                  <span class="subtitle_data" v-if="Show.phoneNumber">
                    Phone Number:
                  </span>

                  <span
                    class="subtitle_data font-weight-bold ml-1 mb-4"
                    v-if="Show.phoneNumber"
                  >
                    {{ Show.phoneNumber }}
                  </span>
                </div>
                <p class="subtitle_data" v-if="Show.billingAddressLine1">
                  Billing Address:
                </p>

                <p class="subtitle_data" v-if="Show.billingAddressLine1">
                  {{ Show.billingAddressLine1 }} <br />
                  {{ Show.billingAddressLine2 }}
                  <br v-if="Show.billingAddressLine2" />
                  {{ Show.billingAddressCity }} <br />
                  {{ Show.billingAddressState }}
                  <br v-if="Show.billingAddressState" />
                  {{ Show.billingAddressPostal }} <br />
                  {{ Show.billingAddressCountry }}
                </p>

                <p class="subtitle_data" v-if="Show.deliveryAddressLine1">
                  Delivery Address:
                </p>
                <p class="subtitle_data" v-if="Show.deliveryAddressLine1">
                  {{ Show.deliveryAddressLine1 }} <br />
                  {{ Show.deliveryAddressLine2 }}
                  <br v-if="Show.deliveryAddressLine2" />
                  {{ Show.deliveryAddressCity }} <br />
                  {{ Show.deliveryAddressState }}
                  <br v-if="Show.deliveryAddressLineState" />
                  {{ Show.deliveryAddressPostal }} <br />
                  {{ Show.deliveryAddressCountry }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          class=" col-12 col-sm-6 col-md-8 col-lg-8 col-xl-8 col"
          v-if="user.userType === 'supplier' && companyOverview"
        >
          <v-card class="data-container-card card-height">
            <v-row>
              <v-col cols="6" md="6">
                <v-card-text class="text-left">
                  <p class="title_style title_align">
                    Company Overview
                  </p>
                </v-card-text>
              </v-col>
              <v-col cols="6" md="6" class="btn-container">
                <md-button @click="edittwoClicked" class="btn_style"
                  >Edit</md-button
                >
              </v-col>
              <v-col cols="12">
                <v-divider class="company_overview_divider_style" />
              </v-col>
            </v-row>
            <v-row
              v-if="
                user.brandName ||
                  Show.companyWebsite ||
                  Show.registrationNumber ||
                  (Show.establishedCountry && Show.establishedYear) ||
                  Show.companyMission ||
                  Show.companyVision ||
                  Show.valueAddedServices
              "
            >
              <v-col cols="6" style="margin-top: -18px;">
                <v-col
                  cols="12"
                  class="text-left ml-5"
                  style="line-height:40px;"
                >
                  <div v-if="user.brandName">
                    <span class="subtitle_data">
                      Name:
                    </span>
                    <span class="subtitle_data font-weight-bold">
                      {{ form.brandName }}
                    </span>
                  </div>
                  <div v-if="Show.companyWebsite">
                    <span class="subtitle_data">
                      Website:
                    </span>
                    <span class="subtitle_data font-weight-bold">
                      {{ Show.companyWebsite }}
                    </span>
                  </div>
                  <div v-if="Show.registrationNumber">
                    <span class="subtitle_data">
                      Registation Number:
                    </span>
                    <span class="subtitle_data font-weight-bold">
                      {{ Show.registrationNumber }}
                    </span>
                  </div>
                  <div v-if="Show.establishedCountry && Show.establishedYear">
                    <span class="subtitle_data">
                      Established:
                    </span>
                    <span class="subtitle_data">
                      {{ Show.establishedCountry }}, {{ Show.establishedYear }}
                    </span>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  class="text-left ml-5"
                  v-if="Show.companyMission"
                >
                  <p class="subtitle_data">
                    Company Mission:
                  </p>
                  <p class="paragraph_data">
                    {{ Show.companyMission }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  class="text-left ml-5"
                  v-if="Show.companyVision"
                >
                  <p class="subtitle_data">
                    Company Vision:
                  </p>
                  <p class="paragraph_data">
                    {{ Show.companyVision }}
                  </p>
                </v-col>
                <v-col
                  cols="12"
                  class="text-left ml-5"
                  v-if="Show.valueAddedServices"
                >
                  <p class="subtitle_data">
                    Value Added Services:
                  </p>
                  <p class="paragraph_data">
                    {{ Show.valueAddedServices }}
                  </p>
                </v-col>
              </v-col>

              <v-col cols="6" v-if="form.selectedProductTypes">
                <div
                  v-if="Show.products.length || Show.supplierRoles.length"
                  class="products-container"
                >
                  <div v-if="Show.products.length" class="md-layout-item">
                    <div class="subtitle_data data-margin">
                      Products:
                    </div>
                    <div
                      class="subtitle_data font-weight-bold data-margin"
                      v-for="selected in Show.products"
                      :key="selected"
                    >
                      {{ getProductTypeName(selected) }}
                    </div>
                  </div>
                  <div v-if="Show.supplierRoles.length" class="md-layout-item">
                    <div class="subtitle_data data-margin">
                      Roles:
                    </div>
                    <div
                      class="subtitle_data font-weight-bold data-margin"
                      v-for="role in Show.supplierRoles"
                      :key="role.name"
                    >
                      {{ getRoleName(role) }}
                    </div>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="
                Show.employeeCount ||
                  Show.machineCount ||
                  Show.productionCapacity
              "
            >
              <v-col cols="12">
                <div class="more-info-style">
                  <div
                    class="md-layout-item overview-subtitle"
                    v-if="Show.employeeCount"
                  >
                    <span class="subtitle_data">
                      Employees:
                    </span>
                    <span class="subtitle_data ml-1">
                      {{ Show.employeeCount }}
                    </span>
                  </div>
                  <v-divider
                    v-if="Show.employeeCount"
                    vertical
                    class="vertical-hr"
                  />
                  <div
                    class="md-layout-item overview-subtitle"
                    v-if="Show.machineCount"
                  >
                    <span class="subtitle_data">
                      Machines:
                    </span>
                    <span class="subtitle_data ml-1">
                      {{ Show.machineCount }}
                    </span>
                  </div>
                  <v-divider
                    vertical
                    class="vertical-hr"
                    v-if="Show.machineCount"
                  />
                  <div
                    class="md-layout-item overview-subtitle"
                    v-if="Show.productionCapacity"
                  >
                    <span class="subtitle_data">
                      Production Capacity:
                    </span>
                    <span class="subtitle_data ml-1">
                      {{ Show.productionCapacity }}
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="Show.sampleLeadTime || Show.productionLeadTime">
              <v-col cols="12">
                <div class="more-info-style">
                  <div
                    class="md-layout-item overview-subtitle"
                    v-if="Show.sampleLeadTime"
                  >
                    <span class="subtitle_data">
                      Sample Lead Time:
                    </span>
                    <span class="subtitle_data ml-1">
                      {{ Show.sampleLeadTime }}
                      <span
                        class="font-weight-bold"
                        v-if="Show.sampleLeadTime == 1"
                        >Week</span
                      >
                      <span class="font-weight-bold" v-else>Weeks</span>
                    </span>
                  </div>
                  <v-divider
                    vertical
                    class="vertical-hr"
                    v-if="Show.sampleLeadTime && Show.productionLeadTime"
                  />
                  <div
                    class="md-layout-item overview-subtitle"
                    v-if="Show.productionLeadTime"
                  >
                    <span class="subtitle_data">
                      Production Lead Time:
                    </span>
                    <span class="subtitle_data ml-1">
                      {{ Show.productionLeadTime }}
                      <span
                        class="font-weight-bold"
                        v-if="Show.productionLeadTime == 1"
                        >Week</span
                      >
                      <span class="font-weight-bold" v-else>Weeks</span>
                    </span>
                  </div>
                </div>
              </v-col>
              <v-col
                cols="12"
                md="12"
                v-if="Show.productionLeadTime || Show.sampleLeadTime"
              >
              </v-col>
            </v-row>
            <v-row v-if="Show.sampleDevelopmentCondition">
              <v-col cols="6" class="text-left ml-8">
                <p class="subtitle_data">
                  Sample Development Condition:
                </p>
                <p class="paragraph_data">
                  {{ Show.sampleDevelopmentCondition }}
                </p>
              </v-col>
            </v-row>
            <v-row v-if="Show.bulkManufacturingCondition">
              <v-col cols="6" class="text-left ml-8">
                <p class="subtitle_data">
                  Bulk Manufacturing Condition:
                </p>
                <p class="paragraph_data">
                  {{ Show.bulkManufacturingCondition }}
                </p>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        justify="center"
        v-if="user.userType === 'supplier' && !editone && !edittwo"
      >
        <v-col  class="col-12 col-md-4 col-lg-4 col-xl-4" v-if="contactInfo">
          <v-card
            :class="
              Show.contactDetails.length
                ? 'images-container-card card-height'
                : 'data-container-card card-height'
            "
          >
            <v-row>
              <v-col cols="6" md="6">
                <v-card-text class="text-left ml-4">
                  <h2 class="title_style">
                    Contact Details
                  </h2>
                </v-card-text>
              </v-col>
              <v-col cols="6" md="6" class="btn-container">
                <md-button @click="editfour = true" class="btn_style"
                  >Edit</md-button
                >
              </v-col>
              <v-col cols="12">
                <v-divider class="company_overview_divider_style" />
              </v-col>
              <v-col
                md="12"
                align="center"
                class="text-left ml-8 mb-4"
                v-for="contact in Show.contactDetails"
                :key="contact.id"
              >
                <div>
                  <span class="subtitle_data">
                    Name:
                  </span>
                  <span class="subtitle_data ml-1">
                    {{ contact.name }}
                  </span>
                </div>
                <div class="mt-2">
                  <span class="subtitle_data">
                    Title:
                  </span>
                  <span class="subtitle_data ml-1">
                    {{ contact.designation }}
                  </span>
                </div>
                <div class="mt-2">
                  <span class="subtitle_data">
                    Email Address:
                  </span>
                  <span class="subtitle_data ml-1">
                    {{ contact.email }}
                  </span>
                </div>
                <div class="mt-2">
                  <span class="subtitle_data">
                    Phone Number:
                  </span>
                  <span class="subtitle_data font-weight-bold ml-1">
                    {{ contact.mobile_number }}
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-dialog
              v-model="editfour"
              persistent
              content-class="v-dialog-data"
            >
              <v-card class="v-card-data">
                <v-card-title>
                  <p class="title_style">
                    Team Contact Details
                  </p>
                  <v-divider justify="center" class="dialog_divider_style" />
                </v-card-title>
                <v-form ref="contactDetails">
                  <v-container class="py-0 team-details">
                    <v-row>
                      <v-col
                        md="12"
                        v-for="(contact, index) in form.contactobj"
                        :key="index"
                      >
                        <v-row>
                          <v-col md="3">
                            <v-text-field
                              :label="`Name`"
                              v-model="contact.name"
                              :rules="rules.contactNameRules"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col md="3">
                            <v-text-field
                              :label="`Title`"
                              v-model="contact.title"
                              :rules="rules.contactTitleRules"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col md="3">
                            <v-text-field
                              :label="`Email`"
                              v-model="contact.email"
                              :rules="rules.contactEmailRules"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>

                          <v-col md="1">
                            <vue-country-code
                              style="height: 40px; margin-right: 0px;"
                              @onSelect="onSelect(...arguments, index)"
                              :defaultCountry="contact.country"
                            >
                            </vue-country-code>
                          </v-col>

                          <v-col md="2">
                            <v-text-field
                              style="width: 250px; margin-left: 0px;"
                              :label="`Phone Number`"
                              v-model="contact.number"
                              :prefix="contact.dialCode + ' '"
                              :rules="rules.contactPhoneNumberRules"
                              v-on:keypress="PhoneInputNumbersOnly"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>

                      <div class="tooltip mb-5 ml-3">
                        <v-btn
                          class="mr-4 mb-4 add-remove-btn"
                          @click="addContactInput"
                          x-small
                          color="#70D0CE"
                        >
                          <v-icon dark>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                        <span class="tooltiptext">Add Contact</span>
                      </div>
                      <div class="tooltip mb-5">
                        <v-btn
                          class="mr-4 mb-4 add-remove-btn"
                          v-if="form.contactobj.length > 1"
                          @click="removeContactInput"
                          x-small
                          color="#EA7A66"
                        >
                          <v-icon dark>
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <span class="tooltiptext">Remove Contact</span>
                      </div>
                    </v-row>
                  </v-container>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <md-button class="close-btn" @click="editfour = false"
                    >Close
                  </md-button>
                  <md-button class="save-btn" @click="updateContacts"
                    >Save
                  </md-button>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col class="col-12 col-md-8 col-lg-8 col-xl-8" v-if="machineInfo">
          <v-card
            class="machines-container-card"
            :class="
              Show.machines.length
                ? 'machines-container-card card-height'
                : 'data-container-card card-height'
            "
          >
            <v-row>
              <v-col cols="6" md="6">
                <v-card-text class="text-left">
                  <p class="title_style title_align">
                    Machines
                  </p>
                </v-card-text>
              </v-col>
              <v-col cols="6" md="6" class="btn-container">
                <md-button @click="editfive = true" class="btn_style"
                  >Edit</md-button
                >
              </v-col>
              <v-col cols="12" md="12">
                <v-divider
                  justify="center"
                  class="company_overview_divider_style"
                />
              </v-col>
              <v-row
                v-if="Show.machines.length"
                class="machines-data-container col-12 col-md-7 col-lg-7 col-xl-7"
              >
                <v-col>
                  <div class="profile-card-style">
                    <div
                      class="md-layout-item machine-type subtitle_data font-weight-bold"
                    >
                      Machine Type
                    </div>
                    <v-divider vertical class="vertical-hr" />
                    <div
                      class="md-layout-item amount subtitle_data font-weight-bold"
                    >
                      Amount
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row class="machines-data-container mt-0 col-12 col-md-7 col-lg-7 col-xl-7">
                <v-col
                  v-for="machine in Show.machines"
                  :key="machine.id"
                  class="machines-container-alignment col-12"
                >
                  <div class="profile-card-style">
                    <div class="md-layout-item machine-type subtitle_data">
                      <div>{{ machine.machine_type }}</div>
                    </div>
                    <v-divider vertical class="vertical-hr" />
                    <div class="md-layout-item amount subtitle_data">
                      <div>{{ machine.number_of_machines }}</div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-row>
            <v-dialog
              v-model="editfive"
              persistent
              content-class="v-dialog-data"
            >
              <v-card class="v-card-data">
                <v-card-title>
                  <p class="title_style mb-5">
                    Machines
                  </p>
                  <v-divider justify="center" class="dialog_divider_style" />
                </v-card-title>
                <v-form ref="machineDetails">
                  <v-container class="py-0 machine-details">
                    <v-row>
                      <v-col
                        md="12"
                        v-for="(type, index) in form.machineobj"
                        :key="index"
                      >
                        <v-row>
                          <v-col md="6">
                            <v-text-field
                              :label="`Machine Type: ${index + 1}`"
                              v-model="type.type"
                              hint="Type of Machine"
                              outlined
                              dense
                              :rules="rules.machineRequiredRules"
                            ></v-text-field>
                          </v-col>
                          <v-col md="6">
                            <v-text-field
                              :label="`Machine Amount: ${index + 1}`"
                              v-model="type.amount"
                              hint="Amount of Machines"
                              outlined
                              dense
                              type="number"
                              min="0"
                              :rules="rules.machineRequiredRules"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <div class="tooltip mb-5 ml-1">
                        <v-btn
                          class="mr-4 mb-4 ml-2 add-remove-btn"
                          @click="addMachineInput"
                          x-small
                          color="#70D0CE"
                        >
                          <v-icon dark>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                        <span class="tooltiptext">Add Machine</span>
                      </div>
                      <div class="tooltip mb-5">
                        <v-btn
                          class="mr-4 mb-4 add-remove-btn"
                          v-if="form.machineobj.length > 1"
                          @click="removeMachineInput"
                          x-small
                          color="#EA7A66"
                        >
                          <v-icon dark>
                            mdi-minus
                          </v-icon>
                        </v-btn>
                        <span class="tooltiptext">Remove Machine</span>
                      </div>
                    </v-row>
                  </v-container>
                </v-form>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <md-button class="close-btn" @click="editfive = false"
                    >Close
                  </md-button>
                  <md-button class="save-btn" @click="updateMachine"
                    >Save
                  </md-button>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
      <v-col class="user-details ml-2 col-12" v-if="editone">
        <div class="user-details-container">
          <v-card class="user-details-v-card">
            <v-card-title>
              <p class="title_style ml-3">
                Edit Personal Information
              </p>
            </v-card-title>
            <v-card-text class="pt-0">
              <v-form ref="profileDetails" class="mt-5">
                <v-container>
                  <v-row align="center" class="profile-row p-0">
                    <v-col align="right" class="avatar-container col-xs-12 col-sm-3 col-md-2 col-lg-2">
                      <v-avatar v-if="!preview" class="avatar">
                        <v-img :src="imageUrl" />
                      </v-avatar>
                      <v-avatar v-else class="avatar">
                        <v-img :src="preview" />
                      </v-avatar>
                    </v-col>
                    <v-col class="upload-btn-container col-xs-6 col-sm-4 col-md-5 col-lg-5">
                      <md-button
                        class="upload-new"
                        @click="$refs.file.click()"
                        >Upload New
                      </md-button>

                      <md-field style="display:none; !important;">
                        <input type="file" ref="file" accept="image/*" @change="previewImage" />
                      </md-field>
                    </v-col>
                    <v-col class="password-btn-container col-xs-6 col-sm-5 col-md-5 col-lg-5">
                      <md-button
                        class="password_btn_style"
                        @click="editPassword = true"
                      >
                        Change Password
                      </md-button>
                    </v-col>
                    <v-col cols="12">
                      <v-divider class="profile_divider_style" />
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Business Name
                      </p>
                      <v-text-field
                        v-if="user.userType !== 'account_manager'"
                        v-model="form.brandName"
                        :rules="rules.brandNameRules"
                        data-cy="company-name-field"
                        outlined
                        dense
                        maxLength="150"
                      ></v-text-field>

                      <v-text-field
                        v-if="user.userType === 'account_manager'"
                        v-model="form.brandName"
                        placeholder="Business Name"
                        data-cy="company-name-field"
                        outlined
                        dense
                        maxLength="150"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Email
                      </p>
                      <v-text-field
                        v-model="form.email"
                        data-cy="email-field"
                        type="email"
                        outlined
                        dense
                        disabled
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        First Name
                      </p>
                      <v-text-field
                        v-model="form.firstName"
                        :rules="rules.firstNameRules"
                        data-cy="first-name-field"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Last Name
                      </p>
                      <v-text-field
                        v-model="form.lastName"
                        :rules="rules.lastNameRules"
                        data-cy="last-name-field"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-row class="number-and-date-container">
                      <v-col cols="6" md="6" sm="12">
                        <v-row no-gutters>
                          <p class="text-left ml-0 form-label-style">
                            Phone Number
                          </p>
                        </v-row>
                        <v-row no-gutters class="phone-number-row">
                          <v-col
                            cols="1"
                            md="3"
                            sm="3"
                            class="flag-container-col"
                          >
                            <vue-country-code
                              class="flag-container"
                              @onSelect="userNumberSelect"
                              :defaultCountry="country"
                            >
                            </vue-country-code>
                          </v-col>
                          <v-col style="margin-left: 12px;">
                            <v-text-field
                              v-model="form.phoneNumber"
                              :rules="rules.phoneNumberRules"
                              :prefix="form.countryCode + ' '"
                              data-cy="phone-number-field"
                              v-on:keypress="PhoneInputNumbersOnly"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="6" md="6" sm="12">
                        <p class="text-left mb-1 ml-0 form-label-style">
                          Date of Birth
                        </p>
                        <div class="calendar-field">
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="form.dateOfBirth"
                                prepend-icon="mdi-calendar"
                                clearable
                                outlined
                                v-bind="attrs"
                                v-on="on"
                                @click:clear="form.dateOfBirth = null"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              class="calendar-field-date-picker"
                              v-model="form.dateOfBirth"
                              :active-picker.sync="activePicker"
                              :max="
                                new Date(
                                  Date.now() -
                                    new Date().getTimezoneOffset() * 60000
                                )
                                  .toISOString()
                                  .substr(0, 10)
                              "
                              min="1950-01-01"
                              @change="closePicker"
                            ></v-date-picker>
                          </v-menu>
                        </div>
                      </v-col>
                    </v-row>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="mt-8">
                      <p class="title_style">Billing & Delivery Information</p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-card-title class="addess-title"
                        >Billing Address:</v-card-title
                      >
                      <div
                        class="address-container"
                        v-if="
                          form.billingAddressLine1 ||
                            form.billingAddressLine2 ||
                            form.billingAddressState
                        "
                      >
                        <div v-if="form.billingAddressLine1">
                          {{ form.billingAddressLine1 }}
                        </div>
                        <div v-if="form.billingAddressLine2">
                          {{ form.billingAddressLine2 }}
                        </div>
                        <div v-if="form.billingAddressCity">
                          {{ form.billingAddressCity }}
                        </div>
                        <div v-if="form.billingAddressState">
                          {{ form.billingAddressState }}
                        </div>
                        <div v-if="form.billingAddressPostal">
                          {{ form.billingAddressPostal }}
                        </div>
                        <div v-if="form.billingAddressCountry">
                          {{ form.billingAddressCountry }}
                        </div>
                      </div>
                      <v-card-actions>
                        <v-btn
                          icon
                          @click="showBillingAddress = !showBillingAddress"
                          class="show-btn"
                        >
                          <v-icon
                            >{{
                              showBillingAddress
                                ? "mdi-chevron-up"
                                : "mdi-pencil"
                            }}
                          </v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-expand-transition>
                        <div v-show="showBillingAddress">
                          <v-text-field
                            ref="address"
                            v-model="form.billingAddressLine1"
                            label="Address Line 1"
                            :required="true"
                            :rules="billingAddressLineRule"
                          ></v-text-field>
                          <v-text-field
                            ref="address"
                            v-model="form.billingAddressLine2"
                            label="Address Line 2"
                          ></v-text-field>
                          <v-text-field
                            ref="city"
                            v-model="form.billingAddressCity"
                            label="City"
                            :rules="billingAddressCityRule"
                          ></v-text-field>
                          <v-text-field
                            ref="state"
                            v-model="form.billingAddressState"
                            label="State/Province/Region"
                          ></v-text-field>
                          <v-text-field
                            ref="zip"
                            v-model="form.billingAddressPostal"
                            label="ZIP / Postal Code"
                            :rules="billingAddressPostalRule"
                          ></v-text-field>
                          <v-autocomplete
                            ref="country"
                            v-model="form.billingAddressCountry"
                            :items="countries"
                            label="Country"
                            placeholder="Select..."
                            :rules="billingAddressCountryRule"
                          >
                            <template v-slot:item="{ item }">
                              <span>{{ item }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span>{{ item }}</span>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-expand-transition>
                    </v-col>
                    <v-col v-if="billAsDelivery" cols="12" md="6">
                      <v-card-title class="addess-title"
                        >Delivery Address:</v-card-title
                      >
                      <div
                        class="address-container"
                        v-if="
                          form.billingAddressLine1 ||
                            form.billingAddressLine2 ||
                            form.billingAddressState
                        "
                      >
                        <div v-if="form.billingAddressLine1">
                          {{ form.billingAddressLine1 }}
                        </div>
                        <div v-if="form.billingAddressLine2">
                          {{ form.billingAddressLine2 }}
                        </div>
                        <div v-if="form.billingAddressCity">
                          {{ form.billingAddressCity }}
                        </div>
                        <div v-if="form.billingAddressState">
                          {{ form.billingAddressState }}
                        </div>
                        <div v-if="form.billingAddressPostal">
                          {{ form.billingAddressPostal }}
                        </div>
                        <div v-if="form.billingAddressCountry">
                          {{ form.billingAddressCountry }}
                        </div>
                      </div>
                      <v-card-actions>
                        <v-btn
                          icon
                          @click="showDeliveryAddress = !showDeliveryAddress"
                          class="show-btn"
                        >
                          <v-icon
                            >{{
                              showDeliveryAddress
                                ? "mdi-chevron-up"
                                : "mdi-pencil"
                            }}
                          </v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-expand-transition>
                        <div v-show="showDeliveryAddress">
                          <v-text-field
                            ref="address"
                            v-model="form.billingAddressLine1"
                            label="Address Line 1"
                            :required="true"
                            :rules="billingAddressLineRule"
                          ></v-text-field>
                          <v-text-field
                            ref="address"
                            v-model="form.billingAddressLine2"
                            label="Address Line 2"
                          ></v-text-field>
                          <v-text-field
                            ref="city"
                            v-model="form.billingAddressCity"
                            label="City"
                            :rules="billingAddressCityRule"
                          ></v-text-field>
                          <v-text-field
                            ref="state"
                            v-model="form.billingAddressState"
                            label="State/Province/Region"
                          ></v-text-field>
                          <v-text-field
                            ref="zip"
                            v-model="form.billingAddressPostal"
                            label="ZIP / Postal Code"
                            :rules="billingAddressPostalRule"
                          ></v-text-field>
                          <v-autocomplete
                            ref="country"
                            v-model="form.billingAddressCountry"
                            :items="countries"
                            label="Country"
                            placeholder="Select..."
                            :rules="billingAddressCountryRule"
                          >
                            <template v-slot:item="{ item }">
                              <span>{{ item }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span>{{ item }}</span>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-expand-transition>
                    </v-col>
                    <v-col v-else cols="12" md="6">
                      <v-card-title class="addess-title"
                        >Delivery Address:</v-card-title
                      >
                      <div
                        class="address-container"
                        v-if="
                          form.deliveryAddressLine1 ||
                            form.deliveryAddressLine2 ||
                            form.deliveryAddressState
                        "
                      >
                        <div v-if="form.deliveryAddressLine1">
                          {{ form.deliveryAddressLine1 }}
                        </div>
                        <div v-if="form.deliveryAddressLine2">
                          {{ form.deliveryAddressLine2 }}
                        </div>
                        <div v-if="form.deliveryAddressCity">
                          {{ form.deliveryAddressCity }}
                        </div>
                        <div v-if="form.deliveryAddressState">
                          {{ form.deliveryAddressState }}
                        </div>
                        <div v-if="form.deliveryAddressPostal">
                          {{ form.deliveryAddressPostal }}
                        </div>
                        <div v-if="form.deliveryAddressCountry">
                          {{ form.deliveryAddressCountry }}
                        </div>
                      </div>
                      <v-card-actions>
                        <v-btn
                          icon
                          @click="showDeliveryAddress = !showDeliveryAddress"
                          class="show-btn"
                        >
                          <v-icon
                            >{{
                              showDeliveryAddress
                                ? "mdi-chevron-up"
                                : "mdi-pencil"
                            }}
                          </v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-expand-transition>
                        <div v-show="showDeliveryAddress">
                          <v-text-field
                            ref="address"
                            v-model="form.deliveryAddressLine1"
                            label="Address Line 1"
                            :required="true"
                            :rules="deliveryAddressLineRule"
                          ></v-text-field>
                          <v-text-field
                            ref="address"
                            v-model="form.deliveryAddressLine2"
                            label="Address Line 2"
                          ></v-text-field>
                          <v-text-field
                            ref="city"
                            v-model="form.deliveryAddressCity"
                            label="City"
                            :rules="deliveryAddressCityRule"
                          ></v-text-field>
                          <v-text-field
                            ref="state"
                            v-model="form.deliveryAddressState"
                            label="State/Province/Region"
                          ></v-text-field>
                          <v-text-field
                            ref="zip"
                            v-model="form.deliveryAddressPostal"
                            label="ZIP / Postal Code"
                            :rules="deliveryAddressPostalRule"
                          ></v-text-field>
                          <v-autocomplete
                            ref="country"
                            v-model="form.deliveryAddressCountry"
                            :items="countries"
                            label="Country"
                            :rules="deliveryAddressCountryRule"
                          >
                            <template v-slot:item="{ item }">
                              <span>{{ item }}</span>
                            </template>
                            <template v-slot:selection="{ item }">
                              <span>{{ item }}</span>
                            </template>
                          </v-autocomplete>
                        </div>
                      </v-expand-transition>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="profile-col">
                      <v-checkbox v-model="billAsDelivery" color="#47C0BE">
                        <template v-slot:label>
                          <div style="font-size:15px; color:#442D65;">
                            Use Billing address is same as Delivery address
                          </div>
                        </template>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <md-button class="close-btn" @click="editoneClosed"
                >Close
              </md-button>
              <md-button class="save-btn" @click="updateUser">Save </md-button>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
      <v-dialog content-class="v-dialog-custom" v-model="editPassword">
        <v-card class="edit-password">
          <div>
            <p class="title_style mb-8">
              Edit Password
            </p>
          </div>
          <v-form ref="updatePasswordForm">
            <v-container class="py-0 password-fields-container">
              <v-row>
                <v-col cols="12" md="10">
                  <v-text-field
                    v-model="password.old_password"
                    label="Old Password"
                    class="mt-8"
                    type="password"
                    data-cy="old-password-field"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="10">
                  <v-text-field
                    v-model="password.new_password"
                    label="New Password"
                    class="password-textfield"
                    type="password"
                    data-cy="new-password-field"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="10">
                  <v-text-field
                    v-model="password.new_password_confirmation"
                    label="Confirm New Password"
                    class="password-textfield"
                    type="password"
                    data-cy="confirm-password-field"
                  />
                </v-col>
              </v-row>
              <div class="password-policy-container">
                <v-row>
                  <v-col cols="3" class="password-policy-icon">
                    <password-lock />
                  </v-col>
                  <v-col>
                    <p class="password-policy-title">
                      <span>Your password needs to:</span>
                    </p>
                    <ul class="password-policy__items">
                      <li class="policy-item">Include at least one number.</li>
                      <li class="policy-item">
                        Include both lower and upper case characters.
                      </li>
                      <li class="policy-item">
                        Be at least 6 characters long.
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </div>
              <v-col
                cols="12"
                class="text-right mt-5"
                style="padding-right: 0px;"
              >
                <md-button
                  @click="updatePassword"
                  class="mr-0 save-btn"
                  data-cy="update-password-button"
                >
                  Update Password
                </md-button>
              </v-col>
            </v-container>
          </v-form>
        </v-card>
      </v-dialog>
      <v-col md="11" class="ml-2" v-if="edittwo">
        <div class="user-details-container">
          <v-card class="company-details-v-card">
            <v-card-title>
              <p class="title_style ml-3">
                Company Details
              </p>
            </v-card-title>
            <v-card-text height="1000px" class="pt-0">
              <v-container class="py-0">
                <v-form ref="CompanyDetails" class="mt-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Company Registration Number
                      </p>
                      <v-text-field
                        v-model="form.registrationNumber"
                        outlined
                        dense
                        :rules="rules.registrationRules"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Website URL
                      </p>
                      <v-text-field
                        v-model="form.companyWebsite"
                        persistent-hint
                        hint="Must begin with http://"
                        :rules="rules.websiteRules"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Year of Company Establishment
                      </p>
                      <v-select
                        v-model="form.establishedYear"
                        :items="yearArray"
                        item-text="year"
                        item-value="year"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Established Country
                      </p>
                      <v-autocomplete
                        ref="country"
                        v-model="form.establishedCountry"
                        :items="countries"
                        outlined
                        dense
                        placeholder="Select..."
                      >
                        <template v-slot:item="{ item }">
                          <span>{{ item }}</span>
                        </template>
                        <template v-slot:selection="{ item }">
                          <span>{{ item }}</span>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="12">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Value Added Services
                      </p>
                      <v-textarea
                        v-model="form.valueAddedServices"
                        :rules="rules.textAreaRules"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Company Vision
                      </p>
                      <v-textarea
                        v-model="form.companyVision"
                        :rules="rules.textAreaRules"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Company Mission
                      </p>
                      <v-textarea
                        v-model="form.companyMission"
                        :rules="rules.textAreaRules"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>

                    <v-col cols="12" md="12"
                      ><p class="text-left mb-1 ml-0 form-label-style">
                        Product Types
                      </p>
                      <v-select
                        class="selection-field"
                        v-model="form.selectedProductTypes"
                        :items="productTypes"
                        item-value="id"
                        multiple
                        outlined
                        dense
                      >
                        <template v-slot:item="{ item }">
                          {{ item.name }}
                        </template>
                        <template v-slot:selection="{ item }">
                          <span>{{ item.name + ", " }}</span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="12"
                      ><p class="text-left mb-1 ml-0 form-label-style">
                        Roles
                      </p>
                      <v-select
                        class="selection-field"
                        v-model="form.selectedSupplierRoles"
                        :items="roles"
                        item-value="id"
                        multiple
                        outlined
                        dense
                      >
                        <template v-slot:item="{ item }">
                          {{ item.display_name }}
                        </template>
                        <template v-slot:selection="{ item }">
                          <span>{{ item.display_name + ", " }}</span>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-divider class="dialog_divider_style" />
                    </v-col>

                    <v-col cols="12" md="4">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Number of Employees
                      </p>
                      <v-text-field
                        v-model="form.employeeCount"
                        type="number"
                        min="0"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Number of Machines
                      </p>
                      <v-text-field
                        v-model="form.machineCount"
                        type="number"
                        min="0"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Production Capacity
                      </p>
                      <v-text-field
                        v-model="form.productionCapacity"
                        type="number"
                        min="0"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Sample Lead Time (Weeks)
                      </p>
                      <v-text-field
                        v-model="form.sampleLeadTime"
                        type="number"
                        min="0"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Production Lead Time (Weeks)
                      </p>
                      <v-text-field
                        v-model="form.productionLeadTime"
                        type="number"
                        min="0"
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Sample Development Condition
                      </p>
                      <v-textarea
                        v-model="form.sampleDevelopmentCondition"
                        :rules="rules.textAreaRules"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                    <v-col cols="12" md="6">
                      <p class="text-left mb-1 ml-0 form-label-style">
                        Bulk Manufacturing Condition
                      </p>
                      <v-textarea
                        v-model="form.bulkManufacturingCondition"
                        :rules="rules.textAreaRules"
                        outlined
                        dense
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <md-button class="close-btn" @click="edittwoClosed"
                >Close
              </md-button>
              <md-button class="save-btn" @click="updateCompany"
                >Save
              </md-button>
            </v-card-actions>
          </v-card>
        </div>
      </v-col>
    </v-row>
    <v-row class="mt-9 col-12 ml-0" v-if="user.userType === 'supplier'">
      <v-col v-if="companyGallery">
        <SupplierProfileImage
          imageCategory="Company Gallery"
          :companyImages="this.Show.companyImages"
          :baseUrl="this.baseUrl"
          @get-users="getUser"
        />
        <SupplierProfileImage
          imageCategory="Products"
          :companyImages="this.Show.productImages"
          :baseUrl="this.baseUrl"
          @get-users="getUser"
        />
        <SupplierProfileImage
          imageCategory="Certifications"
          :companyImages="this.Show.certificationImages"
          :baseUrl="this.baseUrl"
          @get-users="getUser"
        />
      </v-col>
    </v-row>
    <!-- <v-col cols="12" md="10">
        <v-card>
          <v-row>
          <v-col md="12">
          <v-card-text class="text-left">
              <p class="display-2 black--text">
                Environmental Sustainability
              </p>
              </v-card-text>
               <v-divider/>
          </v-col>
          <v-col
            md="6"
            class="text-left"

            v-for="(question, index) in enviromentalQuestions"
            :key="index"
          >
          <v-row>
            <v-col md="2" align="center">
           <v-avatar
        size="72"
        fab
        >

        <v-img :src="imageUrl" />
        </v-avatar>
            </v-col>
            <v-col md="8" class="pa-0" justify="center">
          <span class="font-weight-bold"> {{question.question}}</span>

          <p> {{question.caption}}</p>
            </v-col>
            <v-col md="2" align="center" justify="center">
           <v-checkbox
              class="purple-input inlineBlock"
              v-model="form.environmentalSelected"
              :value="question.id"
            />
            </v-col>
          </v-row>
            </v-col>
          </v-row>
          <md-button class="md-raised md-primary"  @click="updateQuestions">save</md-button>
           </v-card>
      </v-col>-->
  </v-container>
</template>

<script>
import auth from "../../../auth";
import notification from "../../../notification";
import restAdapter from "../../../restAdapter";
import * as conf from "../../../conf.yml";
import countryList from "country-list";
import FileUpload from "../../../components/FileUpload.vue";
import utils from "../../../utils";
import defaultImage from "../../../assets/person.png";
import codes from "country-codes-list";
import SupplierProfileImage from "./SupplierProfileImages.vue";

import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
  validatePassword,
} from "vuelidate/lib/validators";
import validationMixin from "../../../validation/validation_mixin";
import {
  confirmBox,
  resetLeaveAlert,
  setLeaveAlert,
} from "../../../utils/confirmBox";
import VComponent from "@/components/base/VComponent.vue";
import AccountManagerDashboard from "../AccountManagerDashboard.vue";
import countryCodes from "country-codes-list";
import Vue from "vue";
import VueCountryCode from "vue-country-code";

Vue.use(VueCountryCode);

/* eslint-disable */
export default {
  components: {
    VComponent,
    FileUpload,
    AccountManagerDashboard,
    SupplierProfileImage,
  },
  name: "UserProfile",
  mixins: [validationMixin],
  validations: {
    password: {
      old_password: {
        required,
        validatePassword,
      },
      new_password: {
        required,
        validatePassword,
      },
      new_password_confirmation: {
        required,
        sameAsPassword: sameAs("new_password"),
      },
    },
  },
  data() {
    return {
      billAsDelivery: false,
      country: "",
      preview: null,
      image: null,
      oldNum: 0,
      user: {},
      imageUrl: defaultImage,
      avatar: false,
      socialAvatar: null,
      countries: [],
      countryCodes: [],
      arr: [],
      countryCodeList: [],
      showBillingAddress: true,
      showDeliveryAddress: true,
      activePicker: null,
      menu: false,
      productTypes: [],
      services: [],
      roles: [],
      yearArray: [],
      //Dialogs
      editone: false,
      edittwo: false,
      editthree: false,
      editfour: false,
      editfive: false,
      editsix: false,
      //Images
      files: [],
      fileInput: [],
      imageCategories: ["Company Gallery", "Products", "Certifications"],
      acceptedFileTypes: utils.acceptedFileTypes,
      showOption: false,
      UserProfileCard: true,
      companyOverview: true,
      contactInfo: true,
      machineInfo: true,
      companyGallery: true,
      editPassword: false,
      Show: {
        //user
        firstName: null,
        lastName: null,
        brandName: null,
        phoneNumber: null,
        dateOfBirth: null,
        billingAddressLine1: null,
        billingAddressLine2: null,
        billingAddressCity: null,
        billingAddressState: null,
        billingAddressPostal: null,
        billingAddressCountry: null,
        deliveryAddressLine1: null,
        deliveryAddressLine2: null,
        deliveryAddressCity: null,
        deliveryAddressState: null,
        deliveryAddressPostal: null,
        deliveryAddressCountry: null,

        //Company
        companyMission: null,
        companyVision: null,
        companyWebsite: null,
        registrationNumber: null,
        establishedYear: null,
        establishedCountry: null,
        valueAddedServices: null,
        supplierRoles: [],
        products: [],
        employeeCount: null,
        machineCount: null,
        productionCapacity: null,
        sampleLeadTime: null,
        productionLeadTime: null,
        bulkManufacturingCondition: null,
        sampleDevelopmentCondition: null,
        //Contacts
        contactDetails: [],
        //Machines
        machines: [],
        //Images
        images: [],
        companyImages: [],
        productImages: [],
        certificationImages: [],
      },
      form: {
        firstName: null,
        lastName: null,
        brandName: null,
        phoneNumber: null,
        countryCode: null,
        id: null,
        dateOfBirth: null,
        billingAddressLine1: null,
        billingAddressLine2: null,
        billingAddressCity: null,
        billingAddressState: null,
        billingAddressPostal: null,
        billingAddressCountry: null,
        deliveryAddressLine1: null,
        deliveryAddressLine2: null,
        deliveryAddressCity: null,
        deliveryAddressState: null,
        deliveryAddressPostal: null,
        deliveryAddressCountry: null,
        //Company Overview
        establishedYear: null,
        establishedCountry: null,
        valueAddedServices: null,
        selectedProductTypes: [],
        companyVision: null,
        companyMission: null,
        companyWebsite: null,
        registrationNumber: null,
        selectedServices: [],
        employeeCount: null,
        machineCount: null,
        productionCapacity: null,
        sampleLeadTime: null,
        productionLeadTime: null,
        bulkManufacturingCondition: null,
        sampleDevelopmentCondition: null,
        selectedSupplierRoles: [],
        //Machines
        machineobj: [],
        //Contact Details
        contactobj: [],
        //Company Hierarchy
        companyStructure: [],
        contactName: null,
        contactDesignation: null,
        contactEmail: null,
        contactNumber: null,
        //Image
        imageCaption: null,
        imageDescription: null,
        imageCategory: "Company Gallery",
        //Question Answers
        environmentalSelected: [],
      },
      password: {
        old_password: null,
        new_password: null,
        new_password_confirmation: null,
      },
      rules: {
        firstNameRules: [
          (value) => !!value || "First name is required",

          (value) =>
            (value && value.length >= 3) ||
            "First name should have at least 3 characters",

          (value) =>
            (value && value.length <= 150) ||
            "First name should have maximum 150 characters",
        ],
        lastNameRules: [
          (value) => !!value || "Last name is required.",
          (value) =>
            (value && value.length >= 3) ||
            "Last name should have at least 3 characters",
          (value) =>
            (value && value.length <= 150) ||
            "Last name should have maximum 150 characters",
        ],
        brandNameRules: [
          (value) => !!value || "Business name is required.",
          (value) =>
            (value && value.length >= 1) ||
            "Business name should have at least 1 characters",
          (value) =>
            (value && value.length <= 150) ||
            "Business name should have maximum 150 characters",
        ],
        phoneNumberRules: [
          (value) =>
            !value ||
            (value && value.length >= 7 && value.length <= 16) ||
            "Phone number should have minimum 7 digits and maximum 16 digits",
        ],
        websiteRules: [
          (value) =>
            !value ||
            (value &&
              /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(
                value
              )) ||
            "Invalid website url",
        ],
        establishedYearRules: [
          (value) =>
            !value ||
            (value && parseInt(value) <= 9999 && parseInt(value) >= 1000) ||
            "Year should have 4 characters",
        ],
        registrationRules: [
          (value) =>
            !value ||
            (value && value.length <= 150) ||
            "Must be no more than 150 characters",
        ],
        textAreaRules: [
          (value) =>
            !value ||
            (value && value.length <= 255) ||
            "Must be no more than 255 characters",
        ],
        contactNameRules: [
          (value) => !!value || "Name is required.",
          (value) =>
            !value ||
            (value && value.length >= 3) ||
            "Name should have at least 3 characters",
          (value) =>
            (value && value.length <= 150) ||
            "Name should have maximum 150 characters",
          (value) => /^[A-Za-z\s]+$/.test(value) ||
            'Name must contain only letters and spaces',
        ],
        contactTitleRules: [
          (value) => !!value || "Title is required.",
          (value) =>
            (value && value.length <= 150) ||
            "Title should have maximum 150 characters",
          (value) => /^[A-Za-z\s]+$/.test(value) ||
            'Title must contain only letters and spaces',
        ],
        contactEmailRules: [
          (value) => !!value || "Email is required.",
          (value) =>
            !value || (value && /\S+@\S+\.\S+/.test(value)) || "Invalid Email",
          (value) =>
            !value ||
            (value && value.length <= 150) ||
            "Email should have maximum 150 characters",
        ],
        contactPhoneNumberRules: [
          (value) => !!value || "Phone Number is required",
          (value) =>
            !value ||
            (value && value.length >= 7 && value.length <= 16) ||
            "Phone number should have minimum 7 digits and maximum 16 digits",
        ],
        machineRequiredRules: [(value) => !!value || "Required field"],
        captionRules: [(value) => !!value || "Caption is required."],
      },
      ignoreFormEdit: false,
      ignorePasswordEdit: false,
      routeLeaveAlertForm: false,
      routeLeaveAlertPassword: false,
    };
  },

  watch: {
    form: {
      handler: function() {
        if (!this.ignoreFormEdit) {
          this.routeLeaveAlertForm = true;
        }
        this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
      },
      deep: true,
    },
    password: {
      handler: function() {
        if (!this.ignorePasswordEdit) {
          this.routeLeaveAlertPassword = true;
        }
        this.ignorePasswordEdit = setLeaveAlert(this.ignorePasswordEdit);
      },
      deep: true,
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    userNumberSelect({ name, iso2, dialCode }) {
      this.form.countryCode = "+" + dialCode;
    },
    onSelect({ name, iso2, dialCode }, index) {
      this.form.contactobj = this.form.contactobj.map((item, ind) => {
        if (ind === index) {
          return {
            ...this.form.contactobj[index],
            country: iso2,
            dialCode: "+" + dialCode,
          };
        }
        return { ...item };
      });
    },

    getUser() {
      restAdapter
        .post("/api/user/display_user_profile/" + auth.user.id, {
          preview: false,
        })
        .then((res) => {
          //User Data
          this.form.firstName = res.data.user.first_name;
          this.form.lastName = res.data.user.last_name;
          this.form.email = res.data.user.email;
          this.form.phoneNumber = res.data.user.phone_number;
          this.form.dateOfBirth = this.formatDate(res.data.user.date_of_birth);

          this.Show.firstName = res.data.user.first_name;
          this.Show.lastName = res.data.user.last_name;
          this.Show.email = res.data.user.email;

          if (res.data.user.brand_name != null) {
            this.form.brandName = res.data.user.brand_name;
            this.Show.brandName = res.data.user.brand_name;
          } else {
            this.Show.brandName = "";
            this.form.brandName = "";
          }
          this.Show.phoneNumber = res.data.user.phone_number;
          this.Show.dateOfBirth = this.formatDate(res.data.user.date_of_birth);

          if (this.Show.phoneNumber === null) {
            this.form.countryCode = "";
          } else {
            this.Show.phoneNumber = this.Show.phoneNumber.toString();

            this.form.countryCode = this.Show.phoneNumber
              .split(" ")[0]
              .substring(-1);
            this.form.phoneNumber = this.Show.phoneNumber.split(" ")[1];
          }

          const countryCodes = codes.customList(
            "countryCode",
            "+{countryCallingCode}"
          );
          const obj = Object.entries(countryCodes);

          const selectedCountry = obj.find(
            (country) => country[1] == this.form.countryCode
          );
          this.country = (selectedCountry && selectedCountry[0]) || null;

            this.form.billingAddressLine1 = res.data.user.billing_address_line1;
            this.Show.billingAddressLine1 = res.data.user.billing_address_line1;

            this.form.billingAddressLine2 = res.data.user.billing_address_line2;
            this.Show.billingAddressLine2 = res.data.user.billing_address_line2;

            this.form.billingAddressCity = res.data.user.billing_address_city;
            this.Show.billingAddressCity = res.data.user.billing_address_city;

            this.form.billingAddressState = res.data.user.billing_address_state;
            this.Show.billingAddressState = res.data.user.billing_address_state;

            this.form.billingAddressPostal = res.data.user.billing_address_post_code;
            this.Show.billingAddressPostal = res.data.user.billing_address_post_code;

            this.form.billingAddressCountry = res.data.user.billing_address_country;
            this.Show.billingAddressCountry = res.data.user.billing_address_country;

            this.form.deliveryAddressLine1 = res.data.user.delivery_address_line1;
            this.Show.deliveryAddressLine1 = res.data.user.delivery_address_line1;

            this.form.deliveryAddressLine2 = res.data.user.delivery_address_line2;
            this.Show.deliveryAddressLine2 = res.data.user.delivery_address_line2;

            this.form.deliveryAddressCity = res.data.user.delivery_address_city;
            this.Show.deliveryAddressCity = res.data.user.delivery_address_city;

            this.form.deliveryAddressState = res.data.user.delivery_address_state;
            this.Show.deliveryAddressState = res.data.user.delivery_address_state;

            this.form.deliveryAddressPostal = res.data.user.delivery_address_post_code;
            this.Show.deliveryAddressPostal = res.data.user.delivery_address_post_code;

            this.form.deliveryAddressCountry = res.data.user.delivery_address_country;
            this.Show.deliveryAddressCountry = res.data.user.delivery_address_country;

          if (this.user.userType == "supplier") {
            //Clear data
            this.Show.contactDetails = [];
            this.form.contactobj = [];
            this.Show.machines = [];
            this.form.machineobj = [];
            this.form.selectedProductTypes = [];
            this.Show.products = [];
            this.Show.supplierRoles = [];
            this.Show.supplierRoles = [];
            this.Show.images = [];

            //Contacts
            const countryCodes = codes.customList(
              "countryCode",
              "+{countryCallingCode}"
            );
            const obj = Object.entries(countryCodes);
            for (let i = 0; i < res.data.contact_details.length; i++) {
              this.Show.contactDetails.push(res.data.contact_details[i]);
              const countryCode = res.data.contact_details[
                i
              ].mobile_number.split(" ")[0];
              const phoneNumber = res.data.contact_details[
                i
              ].mobile_number.split(" ")[1];
              const selectedCountry = obj.find(
                (country) => country[1] == countryCode
              );

              this.form.contactobj.push({
                name: res.data.contact_details[i].name,
                title: res.data.contact_details[i].designation,
                email: res.data.contact_details[i].email,
                country: selectedCountry ? selectedCountry[0] : false,
                dialCode: selectedCountry ? countryCode : "",
                number: selectedCountry
                  ? phoneNumber
                  : res.data.contact_details[i].mobile_number,
              });
            }

            //Machines
            for (let i = 0; i < res.data.machine_types.length; i++) {
              this.Show.machines.push(res.data.machine_types[i]);
              this.form.machineobj.push({
                type: res.data.machine_types[i].machine_type,
                amount: res.data.machine_types[i].number_of_machines,
              });
            }
            //Products
            for (let i = 0; i < res.data.company_products.length; i++) {
              this.form.selectedProductTypes.push(
                res.data.company_products[i].product_id
              );
              this.Show.products.push(res.data.company_products[i].product_id);
            }

            //Images
            for (let i = 0; i < res.data.gallery_image_data.length; i++)
              this.Show.images.push(res.data.gallery_image_data[i]);
            this.Show.companyImages = this.Show.images.filter(function(u) {
              return u.file_type == 1;
            });
            this.Show.productImages = this.Show.images.filter(function(u) {
              return u.file_type == 2;
            });
            this.Show.certificationImages = this.Show.images.filter(function(
              u
            ) {
              return u.file_type == 3;
            });

            //Get Company Data
            this.Show.supplierRoles = auth.user.subRoles;
            this.form.selectedSupplierRoles = auth.user.subRoles;
              this.form.companyMission = res.data.company.mission;
              this.Show.companyMission = res.data.company.mission;
              this.form.companyVision = res.data.company.vision;
              this.Show.companyVision = res.data.company.vision;

              this.form.valueAddedServices =
                res.data.company.value_added_services;
              this.Show.valueAddedServices =
                res.data.company.value_added_services;

              this.form.establishedCountry =
                res.data.company.established_country;
              this.Show.establishedCountry =
                res.data.company.established_country;

              this.form.establishedYear = res.data.company.established_year;
              this.Show.establishedYear = res.data.company.established_year;

              this.form.registrationNumber =
                res.data.company.business_registration_number;
              this.Show.registrationNumber =
                res.data.company.business_registration_number;

              this.form.companyWebsite = res.data.company.website_url;
              this.Show.companyWebsite = res.data.company.website_url;


              this.form.sampleDevelopmentCondition =
                res.data.company.sample_development_condition;
              this.Show.sampleDevelopmentCondition =
                res.data.company.sample_development_condition;

              this.form.bulkManufacturingCondition =
                res.data.company.bulk_manufacturing_condition;
              this.Show.bulkManufacturingCondition =
                res.data.company.bulk_manufacturing_condition;

              this.form.employeeCount = res.data.company.number_of_employees;
              this.Show.employeeCount = res.data.company.number_of_employees;

              this.form.machineCount = res.data.company.number_of_machines;
              this.Show.machineCount = res.data.company.number_of_machines;

              this.form.productionCapacity =
                res.data.company.production_capacity;
              this.Show.productionCapacity =
                res.data.company.production_capacity;

              this.form.sampleLeadTime = res.data.company.sample_lead_time;
              this.Show.sampleLeadTime = res.data.company.sample_lead_time;

              this.form.productionLeadTime =
                res.data.company.production_lead_time;
              this.Show.productionLeadTime =
                res.data.company.production_lead_time;

          }
          if (res.data.user.avatar == "users/default.png" && auth.user.avatar) {
            this.avatar = true;
            this.imageUrl = auth.user.avatar;
          } else {
            restAdapter
              .getWithOutCache("/api/user/image_show/" + this.user.id)
              .then((res) => {
                if (res.data.image) {
                  this.avatar = false;
                  this.imageUrl = res.data.image;
                }
              });
          }

          this.ignoreFormEdit = true;
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status === 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong");
          }
        });
    },
    PhoneInputNumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    updateProfilePicture() {
      if (!this.$refs.file.files.length) {
        notification.error("Please select an image");
      }

      if (this.$refs.file.files[0].size > 2 * 1024 * 1024) {
        notification.error("File size should be less than 2 MB");
        this.saving = false;
        return;
      }

      const file = this.$refs.file.files[0];
      const formData = new FormData();
      formData.append("file", file);

      restAdapter
        .post("/api/user/files_upload/" + this.user.id, formData)
        .then((res) => {
          if (res.status === 200) {
            this.$emit("file-uploaded");
            notification.success("Profile Picture Updated Successfully");
            this.getImage();
            this.$refs.file.value = null;
          } else {
            notification.error("Something went wrong");
          }
        });
    },

    getUserType(type) {
      switch (type) {
        case "account_manager":
          return "Account Manager";
        case "customer":
          return "Buyer";
        case "supplier":
          return "Supplier";
        default:
          return type;
      }
    },
    getProductTypes() {
      return restAdapter.get("/api/inquire_product_types");
    },
    getServices() {
      return restAdapter.get("/api/inquire_services");
    },
    getRoles() {
      return restAdapter.get("/api/login/display_supplier_role");
    },
    clearFiles() {
      this.$refs.images.value = null;
    },
    uploadFiles() {
      for (let i = 0; i < this.$refs.images.files.length; i++) {
        formData.append(`image[${i}]`, this.form.machineobj[i].type);
      }
    },
    getImage() {
      this.imageUrl = this.baseUrl + "/api/user/image_show/" + this.user.id;
    },
    updateUser() {
      if (!this.$refs.profileDetails.validate()) {
        return;
      }
      if (this.billAsDelivery) {
        this.form.deliveryAddressLine1 = this.form.billingAddressLine1;
        this.form.deliveryAddressLine2 = this.form.billingAddressLine2;
        this.form.deliveryAddressCity = this.form.billingAddressCity;
        this.form.deliveryAddressState = this.form.billingAddressState;
        this.form.deliveryAddressPostal = this.form.billingAddressPostal;
        this.form.deliveryAddressCountry = this.form.billingAddressCountry;
      }
      const formData = new FormData();
      formData.append("user_type", this.user.userType);
      formData.append("first_name", this.form.firstName);
      formData.append("last_name", this.form.lastName);
      formData.append("brand_name", this.form.brandName);
      if (this.form.phoneNumber != null)
        formData.append(
          "phone_number",
          this.form.countryCode + " " + this.form.phoneNumber
        );
      if (this.form.dateOfBirth != null)
        formData.append(
          "date_of_birth",
          this.formatDate(this.form.dateOfBirth)
        );
      if (this.form.billingAddressLine1 != null) {
        formData.append("billing_address_line1", this.form.billingAddressLine1);
        formData.append("billing_address_city", this.form.billingAddressCity);
        formData.append(
          "billing_address_postal",
          this.form.billingAddressPostal
        );
        formData.append(
          "billing_address_country",
          this.form.billingAddressCountry
        );
        if (this.form.billingAddressLine2 != null)
          formData.append(
            "billing_address_line2",
            this.form.billingAddressLine2
          );
        if (this.form.billingAddressState != null)
          formData.append(
            "billing_address_state",
            this.form.billingAddressState
          );
      }

      if (this.form.deliveryAddressLine1 != null) {
        formData.append(
          "delivery_address_line1",
          this.form.deliveryAddressLine1
        );
        formData.append("delivery_address_city", this.form.deliveryAddressCity);
        formData.append(
          "delivery_address_postal",
          this.form.deliveryAddressPostal
        );
        formData.append(
          "delivery_address_country",
          this.form.deliveryAddressCountry
        );
        if (this.form.deliveryAddressLine2 != null)
          formData.append(
            "delivery_address_line2",
            this.form.deliveryAddressLine2
          );
        if (this.form.deliveryAddressState != null)
          formData.append(
            "delivery_address_state",
            this.form.deliveryAddressState
          );
      }
      restAdapter
        .post("/api/update_profile", formData)
        .then(() => {
          notification.success("User Information Updated");

          let userData = auth.user;
          userData.firstName = this.form.firstName;
          userData.lastName = this.form.lastName;
          userData.brandName = this.form.brandName;
          userData.phoneNumber = this.form.phoneNumber;
          auth.updateAuth(userData, true);
          this.getUser();
          this.editone = false;
          this.userCards();
          this.routeLeaveAlertForm = false;
          if (!this.routeLeaveAlertPassword) {
            resetLeaveAlert();
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    updateCompany() {
      if (!this.$refs.CompanyDetails.validate()) {
        return;
      }
      const formData = new FormData();
      if (this.form.registrationNumber)
        formData.append(
          "business_registration_number",
          this.form.registrationNumber
        );
      if (this.form.companyWebsite)
        formData.append("website_url", this.form.companyWebsite);
      if (this.form.establishedYear)
        formData.append("established_year", this.form.establishedYear);
      if (this.form.establishedCountry)
        formData.append("established_country", this.form.establishedCountry);
      if (this.form.companyVision)
        formData.append("vision", this.form.companyVision);
      if (this.form.companyMission)
        formData.append("mission", this.form.companyMission);
      if (this.form.valueAddedServices)
        formData.append("value_added_services", this.form.valueAddedServices);
      if (this.form.machineCount)
        formData.append("number_of_machines", this.form.machineCount);
      if (this.form.employeeCount)
        formData.append("number_of_employees", this.form.employeeCount);
      if (this.form.productionCapacity)
        formData.append("production_capacity", this.form.productionCapacity);
      if (this.form.sampleLeadTime)
        formData.append("sample_lead_time", this.form.sampleLeadTime);
      if (this.form.productionLeadTime)
        formData.append("production_lead_time", this.form.productionLeadTime);
      if (this.form.sampleDevelopmentCondition)
        formData.append(
          "sample_development_condition",
          this.form.sampleDevelopmentCondition
        );
      if (this.form.bulkManufacturingCondition)
        formData.append(
          "bulk_manufacturing_condition",
          this.form.bulkManufacturingCondition
        );
      for (let i = 0; i < this.form.selectedProductTypes.length; i++) {
        formData.append(
          "product_type[" + i + "]",
          this.form.selectedProductTypes[i]
        );
      }

      restAdapter
        .post("/api/update_profile/overview", formData)
        .then(() => {
          notification.success("Company Information Updated");
          this.updateSupplierRoles();
          this.getUser();
          this.edittwo = false;
          this.userCards();
          this.routeLeaveAlertForm = false;
          if (!this.routeLeaveAlertPassword) {
            resetLeaveAlert();
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    updateMachine() {
      if (!this.$refs.machineDetails.validate()) {
        return;
      }
      const formData = new FormData();
      for (let i = 0; i < this.form.machineobj.length; i++) {
        formData.append(
          `machine_types[${i}][machine_type]`,
          this.form.machineobj[i].type
        );
        formData.append(
          `machine_types[${i}][number_of_machines]`,
          this.form.machineobj[i].amount
        );
      }
      restAdapter
        .post("/api/update_profile/machine_types", formData)
        .then(() => {
          notification.success("Machines Saved");

          let userData = auth.user;
          userData.firstName = this.form.firstName;
          userData.lastName = this.form.lastName;
          userData.brandName = this.form.brandName;
          userData.phoneNumber = this.form.phoneNumber;
          auth.updateAuth(userData, true);
          this.getUser();
          this.editfive = false;
          this.routeLeaveAlertForm = false;
          if (!this.routeLeaveAlertPassword) {
            resetLeaveAlert();
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    updateContacts() {
      if (!this.$refs.contactDetails.validate()) {
        return;
      }
      const formData = new FormData();
      for (let i = 0; i < this.form.contactobj.length; i++) {
        formData.append(
          `contact_details[${i}][name]`,
          this.form.contactobj[i].name
        );
        formData.append(
          `contact_details[${i}][designation]`,
          this.form.contactobj[i].title
        );
        formData.append(
          `contact_details[${i}][email]`,
          this.form.contactobj[i].email
        );
        formData.append(
          `contact_details[${i}][mobile_number]`,
          this.form.contactobj[i].dialCode +
            " " +
            this.form.contactobj[i].number
        );
        formData.append(`contact_details[${i}][address]`, null);
      }
      restAdapter
        .post("/api/update_profile/contact_details", formData)
        .then(() => {
          notification.success("Contact Added");

          let userData = auth.user;
          userData.firstName = this.form.firstName;
          userData.lastName = this.form.lastName;
          userData.brandName = this.form.brandName;
          userData.phoneNumber = this.form.phoneNumber;
          auth.updateAuth(userData, true);
          this.getUser();
          this.editfour = false;
          this.routeLeaveAlertForm = false;
          if (!this.routeLeaveAlertPassword) {
            resetLeaveAlert();
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    previewImage(event) {
      const input = event.target;
      if (input.files) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
        this.updateProfilePicture();
      }
    },
    addMachineInput() {
      this.form.machineobj.push({
        type: "",
        amount: "",
      });
    },
    removeMachineInput() {
      if (this.form.machineobj.length > 1) {
        this.form.machineobj.pop();
      }
    },
    addContactInput() {
      this.form.contactobj.push({
        name: "",
        title: "",
        email: "",
        country: false,
        number: "",
      });
    },
    removeContactInput() {
      if (this.form.contactobj.length > 1) {
        this.form.contactobj.pop();
      }
    },
    updateSupplierRoles() {
      restAdapter
        .post("/api/login/select_supplier_role", {
          is_company: auth.user.isCompany,
          id: this.form.selectedSupplierRoles,
        })
        .then((response) => {
          const userData = {
            isCompany: auth.user.isCompany,
            subRoles: this.form.selectedSupplierRoles,
          };
          auth.updateAuthRoles(userData);
        })
        .catch((error) => {
          this.saving = false;
          if (
            error.response &&
            error.response.status == 400 &&
            error.response.data &&
            error.response.data.error
          ) {
            notification.errors(error.response.data.error);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    updatePassword() {
      if (!this.isValidPassword(this.password.new_password)) {
        const errors = {
          password: ["The new password is invalid"],
        };
        notification.errors(errors);
        return;
      }
      restAdapter
        .post("/api/update_password", this.password)
        .then(() => {
          notification.success("Password updated.");
          this.ignorePasswordEdit = true;
          this.$refs.updatePasswordForm.reset();
          this.routeLeaveAlertPassword = false;
          if (!this.routeLeaveAlertForm) {
            resetLeaveAlert();
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.status == 422 &&
            error.response.data &&
            error.response.data.errors
          ) {
            notification.errors(error.response.data.errors);
          } else if (error.response && error.response.status == 401) {
            const errors = {
              old_password: ["The old password is incorrect"],
            };
            notification.errors(errors);
          } else {
            notification.error("Something went wrong, please try again later.");
          }
        });
    },
    isValidPassword(passowrd) {
      return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})/.test(passowrd);
    },

    closePicker(date) {
      this.$refs.menu.save(date);
    },
    formatDate(date) {
      if (date !== null) {
        return this.$moment(new Date(date)).format("YYYY-MM-DD");
      }
      return null;
    },
    getProductTypeName(id) {
      const productType = this.productTypes.find(
        (productType) => productType.id == id
      );
      return productType && productType.name;
    },
    getServiceName(id) {
      const service = this.services.find((service) => service.id == id);
      return service && service.name;
    },
    getRoleName(id) {
      const role = this.roles.find((role) => role.id == id);
      return role && role.display_name;
    },
    isSupportedFileType(filename) {
      const extension =
        filename.match(/\.[0-9a-z]+$/i) &&
        filename.match(/\.[0-9a-z]+$/i)[0].toLowerCase();
      const supportedFileTypes = this.acceptedFileTypes.split(",");
      return supportedFileTypes.includes(extension);
    },
    changePhoneNumber() {
      this.form.phoneNumber = "";
    },

    editoneClicked() {
      this.editone = true;
      this.companyOverview = false;
      this.contactInfo = false;
      this.machineInfo = false;
      this.companyGallery = false;
      this.UserProfileCard = false;
    },

    editoneClosed() {
      this.editone = false;
      this.userCards();
    },

    userCards() {
      this.companyOverview = true;
      this.contactInfo = true;
      this.machineInfo = true;
      this.companyGallery = true;
      this.UserProfileCard = true;
    },

    edittwoClicked() {
      this.edittwo = true;
      this.companyOverview = false;
      this.contactInfo = false;
      this.machineInfo = false;
      this.companyGallery = false;
      this.UserProfileCard = false;
    },

    edittwoClosed() {
      this.edittwo = false;
      this.userCards();
    },
  },
  computed: {
    billingAddressLineRule() {
      return [
        (v) => {
          if (
            !this.form.billingAddressLine1 &&
            !this.form.billingAddressLine2 &&
            !this.form.billingAddressState &&
            !this.form.billingAddressCity &&
            !this.form.billingAddressPostal &&
            !this.form.billingAddressCountry
          ) {
            return true;
          } else if (!this.form.billingAddressLine1) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    billingAddressCityRule() {
      return [
        (v) => {
          if (
            !this.form.billingAddressLine1 &&
            !this.form.billingAddressLine2 &&
            !this.form.billingAddressState &&
            !this.form.billingAddressCity &&
            !this.form.billingAddressPostal &&
            !this.form.billingAddressCountry
          ) {
            return true;
          } else if (!this.form.billingAddressCity) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    billingAddressPostalRule() {
      return [
        (v) => {
          if (
            !this.form.billingAddressLine1 &&
            !this.form.billingAddressLine2 &&
            !this.form.billingAddressState &&
            !this.form.billingAddressCity &&
            !this.form.billingAddressPostal &&
            !this.form.billingAddressCountry
          ) {
            return true;
          } else if (!this.form.billingAddressPostal) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    billingAddressCountryRule() {
      return [
        (v) => {
          if (
            !this.form.billingAddressLine1 &&
            !this.form.billingAddressLine2 &&
            !this.form.billingAddressState &&
            !this.form.billingAddressCity &&
            !this.form.billingAddressPostal &&
            !this.form.billingAddressCountry
          ) {
            return true;
          } else if (!this.form.billingAddressCountry) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    deliveryAddressLineRule() {
      return [
        (v) => {
          if (
            !this.form.deliveryAddressLine1 &&
            !this.form.deliveryAddressLine2 &&
            !this.form.deliveryAddressState &&
            !this.form.deliveryAddressCity &&
            !this.form.deliveryAddressPostal &&
            !this.form.deliveryAddressCountry
          ) {
            return true;
          } else if (!this.form.deliveryAddressLine1) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    deliveryAddressCityRule() {
      return [
        (v) => {
          if (
            !this.form.deliveryAddressLine1 &&
            !this.form.deliveryAddressLine2 &&
            !this.form.deliveryAddressState &&
            !this.form.deliveryAddressCity &&
            !this.form.deliveryAddressPostal &&
            !this.form.deliveryAddressCountry
          ) {
            return true;
          } else if (!this.form.deliveryAddressCity) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    deliveryAddressPostalRule() {
      return [
        (v) => {
          if (
            !this.form.deliveryAddressLine1 &&
            !this.form.deliveryAddressLine2 &&
            !this.form.deliveryAddressState &&
            !this.form.deliveryAddressCity &&
            !this.form.deliveryAddressPostal &&
            !this.form.deliveryAddressCountry
          ) {
            return true;
          } else if (!this.form.deliveryAddressPostal) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
    deliveryAddressCountryRule() {
      return [
        (v) => {
          if (
            !this.form.deliveryAddressLine1 &&
            !this.form.deliveryAddressLine2 &&
            !this.form.deliveryAddressState &&
            !this.form.deliveryAddressCity &&
            !this.form.deliveryAddressPostal &&
            !this.form.deliveryAddressCountry
          ) {
            return true;
          } else if (!this.form.deliveryAddressCountry) {
            return "This field is required";
          }
          return true;
        },
      ];
    },
  },

  mounted() {
    this.user = { ...auth.user };
    this.baseUrl = conf.default.server.host;
    this.getUser();

    this.socialAvatar = auth.user.avatar;
    this.countries = countryList.getNames();
    this.getProductTypes().then((response) => {
      this.productTypes = response.data.productTypes;
    });
    this.getServices().then((response) => {
      this.services = response.data.services;
    });
    this.getRoles().then((response) => {
      this.roles = response.data.roles;
    });

    const year = new Date().getFullYear();
    this.yearArray = Array.from(
      { length: year - 1900 },
      (value, index) => 1901 + index
    );
  },

  beforeRouteLeave(to, from, next) {
    confirmBox(this.$store.state.routeLeaveAlert, next);
  },
};
</script>
<style lang="scss" scoped>
.mr-0 {
  margin-left: 5%;
}

.inlineBlock {
  min-width: 10%;
  float: left;
  padding: 5px;
}

.tooltip {
  position: relative;
  display: inline-block; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #37474f;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
  width: 120px;
  top: 80%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.formLabel {
  font-size: 1rem !important;
}

.md-button.md-theme-default.md-raised:not([disabled]).md-primary {
  color: #fff;
  color: var(--md-theme-default-text-primary-on-primary, #fff);
  background-color: $tableBorder !important;
}
</style>
<style lang="scss">
.v-dialog-custom {
  width: 50% !important;
}

@media only screen and (max-width: 600px) {
  .v-dialog-custom {
    width: 100% !important;
  }
}

.v-dialog-data {
  width: 80% !important;
  background-color: #fff !important;
}

.v-card-data {
  max-height: 65vh !important;
  overflow: auto;
}

.title_style {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: $tableBorder;
  text-decoration-line: underline;
  text-align: left;
}

.name_style {
  color: $tableBorder;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  margin: 12px 0px 0px 0px;
}

.image_style {
  border: 7px solid $paginateText;
  box-shadow: 0px 6.5045px 4.8836px 1px rgb(61 77 135 / 10%);
}

.preview_image_style {
  border: unset;
  box-shadow: unset;
}

.divider_style {
  border-color: $primaryDark !important;
  margin: 25px 25px 15px 25px;
}

.dialog_divider_style {
  border-color: $primaryDark !important;
  margin: 20px 0px 50px 0px;
}

.profile_divider_style {
  border-color: $primaryDark !important;
  margin: 20px 0px 20px 0px;
}

.company_overview_divider_style {
  border-color: $primaryDark !important;
  margin: -15px 25px 15px 32px;
}

.subtitle_style {
  color: $tableBorder;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.subtitle_data {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: $tableBorder;
}

.paragraph_data {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: $tableBorder;
}

.btn-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  padding: 0px 5px 0px 5px;
}
.upload-btn-container{
  display: flex;
  justify-content: flex-start;
  -webkit-justify-content: flex-start;
  padding: 0;
}

.password-btn-container{
  display: flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

.edit-btn-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: -25px;
}

.edit_btn_style {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
  padding: 0px 25px 0px 25px;
  // text-transform: capitalize;
}

.btn_style {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
  padding: 0px 25px 0px 25px;
  margin-right: 25px;
  // text-transform: capitalize;
}

.password_btn_style {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
}

.user-details-container {
  background-color: $paginateText;
  display: flex;
  justify-content: center;
}

.user-details-v-card {
  max-width: 70% !important;
  box-shadow: none !important;
}

.company-details-v-card {
  max-width: 70% !important;
  box-shadow: none !important;
}

.address-container {
  background-color: $paginateBackground;
  border: 0.5px solid $tableBorder;
  border-radius: 3px;
  text-align: left;
  padding: 20px 50px;
  color: $tableBorder;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.theme--light.v-input input {
  color: $primaryDark !important;
}

.addess-title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $tableBorder;
  margin-bottom: 10px;
  margin-left: -15px;
}

.close-btn {
  color: $tableBorder !important;
  background-color: $paginateText;
  border: 1px solid $tableBorder;
  border-radius: 3px;
  height: 40px;
  // padding: 0px 15px 0px 15px;
  // text-transform: capitalize;
}

.save-btn {
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
  padding: 0px 25px 0px 25px;
  // text-transform: capitalize;
}

.edit-password {
  padding: 30px 55px 10px 55px;
  background: $paginateBackground;
  border: 1px solid $tableBorder;
  border-radius: 8px;
}

.password-policy-container {
  margin-top: 30px;
  background: rgba(71, 48, 104, 0.05);
  border: 0.5px solid $tableBorder;
  border-radius: 3px;
  padding-bottom: 12px;
  text-align: left;
}

.password-policy-title {
  text-align: left;
  margin: 15px 0px 10px 10px;
  font-style: normal !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: $tableBorder;
}

.password-policy__items {
  margin-bottom: 15px;
}

.policy-item {
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: $tableBorder;
}

.password-policy-icon {
  justify-content: center;
  display: flex;
  align-items: center;
}

.password-fields-container {
  padding: 0px;
}

.v-application--is-ltr .v-text-field .v-label {
  color: $primaryDark !important;
}

.v-text-field > .v-input__control > .v-input__slot::before {
  border-color: $primaryDark !important;
}

// .v-overlay__scrim{
//   opacity: unset !important;
//   background: rgba(15, 5, 29, 0.78) !important;
//   backdrop-filter: blur(12px);
// }

.theme--dark.v-overlay {
  background-color: rgba(15, 5, 29, 0.78) !important;
}

.password-textfield {
  margin-top: -20px !important;
}

.form-label-style {
  color: $tableBorder;
  opacity: 0.7;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  border: 0.5px solid $tableBorder !important;
  border-radius: 3px !important;
}

.v-text-field--outlined.v-input--is-focused fieldset {
  border: 1.5px solid $tableBorder !important;
  border-radius: 3px !important;
}

.products-container {
  background-color: #f6f1ff;
  width: auto;
  height: auto;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: left;
  padding: 22px 30px;
  margin: -12px 25px;
  overflow-y: auto;
}

.data-margin {
  margin: 15px 0px;
}

.data-container-card {
  background: $paginateText;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
}

.images-container-card {
  background: $paginateText;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  padding-bottom: 30px;
}

.profile-container-card {
  background: $paginateBackground !important;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border: 1px solid $tableBorder !important;
  border-radius: 7px !important;
  padding-bottom: 30px;
}

.machines-container-card {
  background: $paginateText;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06) !important;
  border-radius: 7px !important;
  padding-bottom: 60px;
}

.profile-card-style {
  margin-right: 10px;
  width: 100%;
  min-height: 52px;
  border-radius: 3px;
  border: 0.5px solid $light;
  background: $light;
  display: flex;
  align-items: center;
  text-align: left;
}
.avatar{
  width: 100% !important;
  height: unset !important;
  max-width: 150px;
  aspect-ratio: 1/1;
}
.upload-new{
  color: $paginateText !important;
  background: $tableBorder;
  border-radius: 3px;
  height: 40px;
}

@media only screen and (max-width: 840px) {
  .upload-new {
    font-size: 11px;
  }
  .password_btn_style {
    font-size: 11px;
  }
}

@media only screen and (max-width: 680px) {
  .upload-new {
    width: 91% !important;
    font-size: 9px;
  }
  .password_btn_style {
    width: 100% !important;
    font-size: 9px;
  }
  .avatar{
    max-width: 77px;
  }
}

.machines-data-container {
  padding: 0px 50px;
}

.amount {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-hr {
  border-color: $primaryDark !important;
  margin: 10px 0px;
}

.machines-container-alignment {
  margin: -8px 0px;
}

.machine-type {
  margin: 0px 0px 0px 12px;
}

.theme--light.v-select .v-select__selections {
  color: $primaryDark !important;
}

.theme--light.v-input textarea {
  color: $primaryDark !important;
}

.team-details {
  padding: 25px;
  margin-top: -15px;
}

.machine-details {
  padding: 25px;
}

.add-remove-btn {
  height: 32px !important;
  width: 32px !important;
  box-shadow: none;
  border-radius: 3.99038px;
}

.title_align {
  margin-left: 16px;
}

.vue-country-select {
  border: 1px solid $tableBorder !important;
}

.vue-country-select:focus-within {
  border-color: $success !important;
}

.more-info-style {
  background: rgba(112, 208, 206, 0.18);
  border-radius: 3px;
  margin-right: 10px;
  width: 80%;
  min-height: 52px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 32px;
  line-break: anywhere;
}

.profile-col {
  margin: -12px 0px 0px 0px;
}

.md-field.md-theme-default:after {
  background-color: $tableBorder;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: $tableBorder !important;
}

.selection-field::v-deep .v-select__selections {
  color: $tableBorder;
}

.number-and-date-container {
  padding: 12px;
}

.theme--light.v-icon {
  color: $primaryDark;
}

.overview-subtitle {
  margin: 0px 15px;
}

.row-alignment {
  margin: -12px 20px;
}

.card-height {
  height: 100%;
}
</style>

<style scoped>
.v-application .v-list .v-list-item--active {
  background-color: #e2daed !important;
}

.theme--light.v-list {
  background: #f3edf7;
  color: #473068;
}

.show-btn {
  background-color: #473068 !important;
}

.calendar-field::v-deep .v-text-field--outlined fieldset {
  height: 45px !important;
}

.calendar-field::v-deep .v-text-field--outlined .v-text-field__slot {
  height: 42px !important;
}

.calendar-field::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 9px !important;
}

.calendar-field::v-deep .v-text-field--enclosed .v-input__prepend-outer {
  margin-top: 9px !important;
}

.calendar-field-date-picker {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.flag-container {
  height: 40px;
}

.flag-container-col {
  display: contents;
}

.phone-number-row {
  margin: -12px 0px 0px 2px;
}
</style>
