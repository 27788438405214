<template>
  <v-row class="md-layout md-gutter account-manager-dashboard">
    <v-col
      cols="12"
      sm="6"
      md="6"
      lg="4"
      xl="3"
      v-for="item in items"
      :key="item.title"
    >
      <router-link :to="item.path">
        <div class="md-layout-item account-manager-dashboard__item">
          <md-card class="card-style">
            <div
              :class="(item.id % 2 === 0) ? 'icon-container-green' : 'icon-container-purple'"
            >
              <md-card-media md-medium :class="item.title === 'Management Dashboard' || item.title === 'Create Collection' ? 'media-content-management-dashboard' : 'media-content'"
              >
              <create-style-icon v-if="item.title === 'Create Style'"/>
              <create-collection-icon v-else-if="item.title === 'Create Collection'"/>
              <img v-else :src="item.image" :alt="item.alt"/>
              </md-card-media>
            </div>
            <md-card-content>
              <div class="title-style">{{ item.title }}</div>
              <div class="subtitle-style">{{ item.description }}</div>
            </md-card-content>
          </md-card>
        </div>
      </router-link>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AccountManagerDashboard",
  data: () => ({
    items: [
      // {
      //   title: "My Inquiries",
      //   description: "Go to your inquiry list",
      //   path: "/app/inquiries/own?inquiry_type=own",
      //   image: require(`../../assets/dashboard-icons/inquiries.png`),
      //   alt: "Inquiry List",
      // },
      {
        id: 1,
        title: "Create Style",
        description: "Create a style now",
        path: "/app/inquiry/new",
        // image: require(`../../assets/dashboard-icons/inquiry.png`),
        alt: "Create Style",
      },
      {
        id: 2,
        title: "Create Collection",
        description: "Create a Collection now",
        path: "/app/collection/new",
        // image: require(`../../assets/dashboard-icons/create_collection.png`),
        alt: "Create Collection",
      },
      {
        id: 3,
        title: "Collection List",
        description: "Go to your Collection list",
        path: "/app/collections",
        image: require(`../../assets/dashboard-icons/inquiries.png`),
        alt: "Collection List",
      },
      {
        id: 4,
        title: "Management Dashboard",
        description: "Manage Styles",
        path: "/app/management_dashboard",
        image: require(`../../assets/dashboard-icons/account_manager_dashboard.png`),
        alt: "Management Dashboard",
      },
      {
        id: 5,
        title: "Approve Suppliers",
        description: "Review and approve suppliers here",
        path: "/app/approve_suppliers",
        image: require(`../../assets/dashboard-icons/approve_suppliers.png`),
        alt: "Approve Suppliers",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.account-manager-dashboard {
  a:not(.md-button):hover {
    text-decoration: none;
  }

  .md-card.md-theme-default:hover {
    .title-style{
      font-weight: 700;
      font-size: 20px;
    }
    box-shadow: 0px 29px 15px rgba(169, 169, 194, 0.19);
  }

  .card-style{
    background: #FFFFFF;
    box-shadow: 0px 6px 11px rgba(187, 187, 219, 0.19);
    border-radius: 8px;
  }
  .icon-container-purple{
    background-color: #ebdaff;
    border-radius: 8px 8px 0px 0px;
  }

  .icon-container-green{
    background-color: #a5e8e6;
    border-radius: 8px 8px 0px 0px;
  }

  .media-content{
    justify-content: center;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin: 40px;
  }
  .media-content-management-dashboard{
    justify-content: center;
    display: inline-block;
    width: 140px;
    height: 100px;
    margin: 40px;
  }

  .title-style{
    color: #473068;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 40px;
  }

  .subtitle-style{
    color: #473068;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}
</style>
